import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/img/gif/logo-gif.gif";
import stars from "../assets/img/gif/stars.gif";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };
  const closeMenu = () => {
    setMenuOpen(false);
    document.body.style.overflow = "";
  };

  const scrollToPosition = (position) => {
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  const handleAccueilClick = (event) => {
    event.preventDefault();
    scrollToPosition(0);
    navigate("/");
    closeMenu();
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (currentScroll < lastScroll) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(false);
      }
      setLastScroll(currentScroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);

  return (
    <div id="navigation" style={{ top: navbarVisible ? "0" : "-90px" }}>
      <div className="primary-navigation">
        {" "}
        <div className="navigation">
          <NavLink to="/">
            <img
              src={logo}
              alt="Logo du Studio céhel"
              onClick={handleAccueilClick}
            />
          </NavLink>
          <ul className="liens-nav">
            <NavLink to="/" onClick={handleAccueilClick}>
              <li>accueil.</li>
            </NavLink>
            <NavLink to="/#realisations-link">
              <li>réalisations.</li>
            </NavLink>
            <NavLink to="/#a-propos-link">
              <li>à propos.</li>
            </NavLink>
            <a href="mailto:celia.letellier@gmail.com">
              <li className="contact-box">contact.</li>
            </a>
          </ul>
        </div>
        <div
          id="btn"
          className={menuOpen ? "btn-menu active" : "btn-menu"}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={menuOpen ? "menu-burger open" : "menu-burger"}>
        <img src={stars} alt="Images d'étoiles" className="bloc-etoile-top" />
        <ul>
          <NavLink to="/">
            <li className="link-border" onClick={handleAccueilClick}>
              accueil.
            </li>
          </NavLink>
          <NavLink to="/#realisations-link" onClick={closeMenu}>
            <li className="link-border">réalisations.</li>
          </NavLink>
          <NavLink to="/#a-propos-link" onClick={closeMenu}>
            <li className="link-border">à propos.</li>
          </NavLink>
          <a href="mailto:celia.letellier@gmail.com">
            <li className="contact-box">contact.</li>
          </a>
        </ul>
        <img
          src={stars}
          alt="Images d'étoiles"
          className="bloc-etoile-bottom"
        />
      </div>
    </div>
  );
};

export default Navigation;
