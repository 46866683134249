import React from "react";
import { Link } from "react-router-dom";
import { UilLinkedin } from "@iconscout/react-unicons";
import { UilGithub } from "@iconscout/react-unicons";

const Footer = () => {
  return (
    <div className="footer">
      <ul>
        <Link to="/">
          <li>accueil.</li>
        </Link>
        <span>|</span>
        <Link to="/#realisations">
          <li>réalisations.</li>
        </Link>
        <span>|</span>
        <Link to="/#a-propos">
          <li>à propos.</li>
        </Link>
        <span>|</span>
        <Link to="mailto:celia.letellier@gmail.com">
          <li>contact.</li>
        </Link>
        <span>|</span>
        <a
          href="https://github.com/ltrcelia?tab=repositories"
          target="_blank"
          rel="noopener noreferrer"
          className="bloc-logo"
        >
          <UilGithub />
          <p className="small">GitHub</p>
        </a>
        <span>|</span>
        <a
          href="https://www.linkedin.com/in/celia-letellier-graphiste-freelance/"
          target="_blank"
          rel="noopener noreferrer"
          className="bloc-logo"
        >
          <UilLinkedin />
          <p className="small">Linkedin</p>
        </a>
      </ul>
    </div>
  );
};

export default Footer;
