import React from "react";
import stars from "../assets/img//gif/stars.gif";
import photoCelia from "../assets/img/photo-celia.png";
import bad from "../assets/img/gif/badminton.gif";
import dessin from "../assets/img/gif/dessin.gif";
import rubis from "../assets/img/gif/rubis.gif";
import uk from "../assets/img/gif/uk.gif";
import ecriture from "../assets/img/gif/ecrire.gif";
import livres from "../assets/img/gif/lire.gif";

const APropos = () => {
  return (
    <div id="a-propos">
      <h2>à propos.</h2>
      <div className="a-propos-container">
        <img src={photoCelia} alt="Célia" />
        <div className="texte-apropos">
          <h2>Hello, je suis Célia.</h2>
          <p>
            <span>Développeuse full-stack (React - Node.js)</span>, j'aime en
            apprendre chaque jour davantage pour nourrir ma passion de la
            communication. Que ce soit au moyen du web, de la vidéo ou du print,
            j'aime me diversifier afin de développer au maximum mes compétences.{" "}
            <br /> <br />
            Aujourd'hui basée en Loire-Atlantique,{" "}
            <span>je recherche une alternance de 15 mois</span> en développement
            full stack React-Node.js à partir d'octobre 2024. N'hésitez pas à me
            contacter pour en savoir plus !
          </p>
        </div>
      </div>
      <div className="anecdotes">
        <h3>Quelques anecdotes à savoir sur moi </h3>
        <p>
          <span>•</span> J’ai une passion obsessionnelle pour les{" "}
          <span>paillettes</span>, plus ça brille mieux c’est.
        </p>
        <p>
          <span>•</span> J’adore la <span>mer</span> mais je déteste m’y
          baigner, un comble pour une Bretonne.
        </p>
        <p>
          <span>•</span> J’aimerais avoir une <span>mini ferme</span> plus tard
          : âne, moutons, poules…
        </p>
        <p>
          <span>•</span> Je passe mon temps à <span>ranger</span>, tout ce qui
          me passe sous la main doit être à sa place.
        </p>
        <p>
          <span>•</span> Je suis une <span>couche tard</span>, je réfléchis et
          travail mieux le soir.
        </p>
        <p>
          <span>•</span> J’adore <span>lire</span> bien que je n’arrive pas
          assez souvent à trouver le temps pour.
        </p>
      </div>
      <div className="encart-pourquoi">
        <h2>Pourquoi travailler avec moi ?</h2>
        <p>
          Forte de mes différentes expériences à l’école, en stage ou en tant
          que freelance, j’ai acquis diverses capacités qui me permettent d’être
          rapide, efficace, autonome, polyvalante, créative et de bien
          communiquer avec les clients pour comprendre leurs besoins.
        </p>
      </div>

      <h2>formations.</h2>
      <div className="formations">
        <div className="formations-container">
          <p>2024-2026</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">Wild Code School</span> (Online)
            Formation Développeur full stack (React - Node.js) <br />
          </p>
        </div>

        <div className="formations-container">
          <p>2023-2024</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">OpenClassroom</span> (Online)
            Formation Développeur web <br /> (spé WordPress)
          </p>
        </div>

        <div className="formations-container">
          <p>2018-2019</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">École Pivaut</span> (Nantes 44)
            Formation Motion design
          </p>
        </div>

        <div className="formations-container">
          <p>2014-2018</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">École Pivaut</span> (Nantes 44)
            Diplômée en Design graphique
          </p>
        </div>

        <div className="formations-container">
          <p>2013-2014</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">Rennes 2</span> (Rennes 35) Licence 1
            Information et Communication
          </p>
        </div>

        <div className="formations-container">
          <p>2010-2013</p>
          <div className="boules"></div>
          <p>
            <span className="noms-ecoles">Lycée Dupuy de Lôme</span> (Lorient
            56) Bac section général <br />
            Filière Littéraire - Option art plastique
          </p>
        </div>
      </div>

      <div className="stars">
        <img src={stars} alt="Etoiles" className="star" />
      </div>
      <span className="border"></span>

      <h2>intérêts.</h2>
      <div className="passions-container">
        <div className="passions">
          <img
            className="img-interet"
            src={dessin}
            alt="Crayon coloriant un rond"
          />
          <p>dessin.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-livre"
            src={livres}
            alt="Pile de livres"
          />
          <p>fantasy - SF.</p>
        </div>
        <div className="passions">
          <img className="img-interet  img-rubis" src={rubis} alt="Rubis" />
          <p>zelda.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-uk"
            src={uk}
            alt="Drapeau du Royaume-Uni"
          />
          <p>royaume-uni.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-ecrire"
            src={ecriture}
            alt="Plume écrit sur une feuille"
          />
          <p>écriture.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-bad"
            src={bad}
            alt="Raquette de badminton et son volant"
          />
          <p>badminton.</p>
        </div>
      </div>
    </div>
  );
};

export default APropos;
